@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  :root {
    --color-primary-50: 255 247 246;
    --color-primary-100: 255 239 238;
    --color-primary-200: 255 215 211;
    --color-primary-300: 255 190 185;
    --color-primary-400: 255 142 133;
    --color-primary-500: 255 93 80;
    --color-primary-600: 230 84 72;
    --color-primary-700: 153 56 48;
    --color-primary-800: 115 42 36;
    --color-primary-900: 77 28 24;
  }

  .theme-tendo {
    --color-primary-50: 243 246 249;
    --color-primary-100: 230 236 242;
    --color-primary-200: 194 208 223;
    --color-primary-300: 157 179 203;
    --color-primary-400: 83 123 164;
    --color-primary-500: 9 66 125;
    --color-primary-600: 8 59 113;
    --color-primary-700: 5 40 75;
    --color-primary-800: 4 30 56;
    --color-primary-900: 3 20 38;
  }

  .tomato {
    --color-primary-50: 244 250 248;
    --color-primary-100: 233 246 242;
    --color-primary-200: 200 232 222;
    --color-primary-300: 166 217 201;
    --color-primary-400: 100 189 161;
    --color-primary-500: 33 161 121;
    --color-primary-600: 30 145 109;
    --color-primary-700: 20 97 73;
    --color-primary-800: 15 72 54;
    --color-primary-900: 10 48 36;
  }
}
